import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject, from, map, mergeMap, of, take, tap } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PostRequest } from '../api/methods';
import { Post } from '../model/post';
import { isPlatformBrowser } from '@angular/common';
import { RoutingService } from './routing.service';

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  private platformId = inject(PLATFORM_ID);
  private gateUrl = environment.postUrl + '/v1';
  public updatePostSubject = new Subject<any>();
  updatePostSubject$ = this.updatePostSubject.asObservable();
  public feedPostsList = new BehaviorSubject<any>([]);
  feedPostsList$ = this.feedPostsList.asObservable();
  public feedPostSettings = new BehaviorSubject<any>({limit: 10, page: 1});
  feedPostSettings$ = this.feedPostSettings.asObservable();
  public feedPostsTotalAmount = new BehaviorSubject<number>(0);
  public currentPostVisited = new BehaviorSubject<string>(null);
  public currentPostVisited$ = this.currentPostVisited.asObservable();
  public feedReloadSubject = new BehaviorSubject<boolean>(false);
  public feedReload$ = this.feedReloadSubject.asObservable();
  public postSavingProcessingSubject = new BehaviorSubject<boolean>(false);
  public postSavingProcessing$ = this.postSavingProcessingSubject.asObservable();


  constructor(
    private http: HttpClient,
    private routesService: RoutingService,
  ) {}

  public getPosts(settings:{limit: number, page: number}): Observable<any> {
    const params = new HttpParams()
      .set('limit', settings.limit.toString())
      .set('page', settings.page.toString());
      return this.http.get<any>(`${this.gateUrl}/posts`, {
        params,
        withCredentials: true
      }).pipe(
        map(response => this.sortPostImagesByPosition(response))
      );
  }

  public getQueuePosts(date: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/queue/list`, {'month': date}, { withCredentials: true });
}

  public getPostById(id: string): Observable<any> {
      return this.http.get<any>(`${this.gateUrl}/posts/${id}`, { withCredentials: true });
  }

  public deletePost(postId: string): Observable<any> {
    return this.http.delete<any>(`${this.gateUrl}/posts/${postId}/hide`, { withCredentials: true }).pipe(take(1));
  }

  public createPost(body: any): Observable<any> {
    return from(new PostRequest(this.http, `${this.gateUrl}/posts`, body).getResponse<any>());
  }

  public getUserPosts(userId: string, limit: number, page: number): Observable<any> {
    const params = new HttpParams()
      .set('limit', limit.toString())
      .set('page', page.toString());
      return this.http.get<any>(`${this.gateUrl}/posts/${userId}/list`, {
        params,
        withCredentials: true
      });
  }

  public patchPost(postId:string, updatedData: any): Observable<any> {
    return this.http.patch<any>(`${this.gateUrl}/posts/${postId}`, updatedData, { withCredentials: true })
    .pipe(
      mergeMap(() => this.getPostById(postId)),
      tap(() => this.postSavingProcessingSubject.next(false))
    );
  }

  public findAndModifyPost(post: Post): void {
    let index = this.feedPostsList.getValue().findIndex(item => item.id === post.id);
    if (index !== -1) this.feedPostsList.getValue()[index] = post;
  }

  public unshiftPostToPostsList(post: Post): void {
    if (this.feedPostsList.getValue().length) {
      this.feedPostsList.getValue().unshift(post);
    }
  }

  public likePost(postId:string): Observable<any> {
    return this.http.post<any>(`${this.gateUrl}/likes/posts/${postId}`,null,  { withCredentials: true });
  }

  public dislikePost(postId:string): Observable<any> {
    return this.http.delete<any>(`${this.gateUrl}/likes/posts/${postId}`,  { withCredentials: true });
  }

  public getLikesListForPost( limit: number, page: number, postId: string,): Observable<any> {
    const params = new HttpParams()
      .set('limit', limit.toString())
      .set('page', page.toString());
      return this.http.get<any>(`${this.gateUrl}/likes/posts/${postId}/list`, {
        params,
        withCredentials: true
      });
  }

  public scrollToPost(): void {
    if (isPlatformBrowser(this.platformId) && this.routesService.prevUrl.includes(this.currentPostVisited.getValue())) {
      setTimeout(() => {
        const element = document.getElementById(this.currentPostVisited.getValue())
        if (element) {
          element.scrollIntoView({ behavior: 'instant' });
          this.currentPostVisited.next(null);
        }
      }, 0)
    }
  }

  public clearPosts(): void {
    this.feedPostsList.next([]);
    this.feedReloadSubject.next(true);
  }

  public resetPostSettings(): void {
    this.feedPostSettings.next({limit: 10, page: 1});
  }

  private sortPostImagesByPosition(response: any): any {
    if (response.data && Array.isArray(response.data)) {
      response.data.forEach((item:any) => {
        if (item.images && Array.isArray(item.images)) {
          item.images.sort((a:any, b:any) => a.position - b.position);
        }
      });
    }
    return response;
  }
}


const data = {
  "total": 12,
  "pageSize": 10,
  "pageNumber": 1,
  "data": [
    {
      "id": "8f694bd2-3297-481d-8d5d-5214f8af3d8d",
      "text": "paid 4: photo",
      "isPaid": false,
      "price": {
        "id": "6c5f37d6-c427-4b7c-9145-efffa9975e51",
        "amount": 4.2,
        "amountInCoins": 420,
        "productType": "post",
        "rawAmount": 4,
        "rawAmountInCoins": 400,
        "fee": 20,
        "archived": false,
        "currency": "USD",
        "discount": null,
        "discountPrice": null,
        "targetId": "8f694bd2-3297-481d-8d5d-5214f8af3d8d",
        "userId": "20463950-ed28-4a96-a13f-37af7100760e",
        "createdAt": "2024-08-15T13:49:59.568Z"
      },
      "commentsEnabled": true,
      "access": "free",
      "state": "published",
      "pinned": false,
      "likes": 0,
      "ownerId": "20463950-ed28-4a96-a13f-37af7100760e",
      "hasPrice": true,
      "liked": false,
      "attachments": [
        {
          "id": "70cf1e86-b513-4c4b-901f-f693bb990d8c",
          "hubId": "ed0cce5a-df2c-4497-abc7-142346434f12",
          "position": 0,
          "file": {
            "id": "ed0cce5a-df2c-4497-abc7-142346434f12",
            "owner": "CREATOR",
            "file": {
              "uploadId": "10eff304-58de-4c77-95a2-fdc16576f747",
              "status": "UPLOADED",
              "name": "22c4d62c-fca5-40fc-a9b8-9aa2dda6f584.mp4",
              "type": "VIDEO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/10eff304-58de-4c77-95a2-fdc16576f747/blur_22c4d62c-fca5-40fc-a9b8-9aa2dda6f584.webp",
              "avatar": null,
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/10eff304-58de-4c77-95a2-fdc16576f747/blur_22c4d62c-fca5-40fc-a9b8-9aa2dda6f584.webp",
              "thumbnail": null,
              "createdAt": "2024-05-23T16:01:56.640Z",
              "updatedAt": "2024-05-23T16:01:57.858Z",
              "deletedAt": null
            },
            "folders": [
              {
                "id": "1414bb56-855c-4919-b4ed-7c1a81917efe",
                "name": "Super",
                "last": [],
                "user": {
                  "id": "20463950-ed28-4a96-a13f-37af7100760e",
                  "nickname": "estrela_mountainboss",
                  "displayName": "estrela_mountain_dog",
                  "isCreator": true,
                  "kycVerified": true,
                  "creatorDetails": {
                    "displayName": "estrela_mountain_dog"
                  },
                  "images": [
                    {
                      "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                      "filename": "dev_avatar_1716195400.webp",
                      "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "avatar",
                      "createdAt": "2024-05-20T09:16:36.924Z"
                    },
                    {
                      "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                      "filename": "dev_background_1716824353.webp",
                      "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "background",
                      "createdAt": "2024-05-27T15:39:15.656Z"
                    }
                  ]
                },
                "createdAt": "2024-05-20T09:32:13.127Z",
                "updatedAt": "2024-05-20T09:32:13.127Z",
                "deletedAt": null
              }
            ],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-23T16:01:57.854Z",
            "updatedAt": "2024-05-23T16:01:57.854Z",
            "deletedAt": null
          }
        }
      ],
      "createdAt": "2024-08-15T13:49:59.562Z",
      "updatedAt": "2024-08-15T13:49:59.562Z",
      "owner": {
        "id": "20463950-ed28-4a96-a13f-37af7100760e",
        "nickname": "estrela_mountainboss",
        "displayName": "estrela_mountain_dog",
        "images": [
          {
            "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
            "filename": "dev_avatar_1716195400.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
            "imageType": "avatar",
            "createdAt": "2024-05-20T09:16:36.924Z"
          },
          {
            "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
            "filename": "dev_background_1716824353.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
            "imageType": "background",
            "createdAt": "2024-05-27T15:39:15.656Z"
          }
        ],
        "isCreator": true
      },
      "likeUsers": [],
      "delayDate": null,
      "countComments": 0
    },
    {
      "id": "8a0ff050-b758-4a5e-8f9b-a1d76530fd11",
      "text": "free: photo",
      "isPaid": false,
      "price": null,
      "commentsEnabled": true,
      "access": "free",
      "state": "published",
      "pinned": false,
      "likes": 0,
      "ownerId": "20463950-ed28-4a96-a13f-37af7100760e",
      "hasPrice": false,
      "liked": false,
      "attachments": [
        {
          "id": "5d75bcd0-c221-41af-822c-c4abfecd79ca",
          "hubId": "ef905ecd-2c8c-4051-badd-a1d0f80902ce",
          "position": 0,
          "file": {
            "id": "ef905ecd-2c8c-4051-badd-a1d0f80902ce",
            "owner": "CREATOR",
            "file": {
              "uploadId": "be2e2ab9-56fb-4529-b0b6-c6d42a97487e",
              "status": "UPLOADED",
              "name": "6fb26d4e-043c-4a11-8c3a-d64c1993ef50.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/be2e2ab9-56fb-4529-b0b6-c6d42a97487e/6fb26d4e-043c-4a11-8c3a-d64c1993ef50.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/be2e2ab9-56fb-4529-b0b6-c6d42a97487e/avatar_6fb26d4e-043c-4a11-8c3a-d64c1993ef50.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/be2e2ab9-56fb-4529-b0b6-c6d42a97487e/blur_6fb26d4e-043c-4a11-8c3a-d64c1993ef50.jpg",
              "createdAt": "2024-05-27T15:03:14.902Z",
              "updatedAt": "2024-07-26T12:49:36.665Z",
              "deletedAt": null
            },
            "folders": [
              {
                "id": "12ac65d8-42b0-4bde-aaeb-681658adfb0b",
                "name": "Yes",
                "last": [],
                "user": {
                  "id": "20463950-ed28-4a96-a13f-37af7100760e",
                  "nickname": "estrela_mountainboss",
                  "displayName": "estrela_mountain_dog",
                  "isCreator": true,
                  "kycVerified": true,
                  "creatorDetails": {
                    "displayName": "estrela_mountain_dog"
                  },
                  "images": [
                    {
                      "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                      "filename": "dev_avatar_1716195400.webp",
                      "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "avatar",
                      "createdAt": "2024-05-20T09:16:36.924Z"
                    },
                    {
                      "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                      "filename": "dev_background_1716824353.webp",
                      "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "background",
                      "createdAt": "2024-05-27T15:39:15.656Z"
                    }
                  ]
                },
                "createdAt": "2024-05-24T13:56:06.712Z",
                "updatedAt": "2024-05-24T13:56:06.712Z",
                "deletedAt": null
              }
            ],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-27T15:03:21.382Z",
            "updatedAt": "2024-05-27T15:03:21.382Z",
            "deletedAt": null
          }
        }
      ],
      "createdAt": "2024-08-15T13:49:24.956Z",
      "updatedAt": "2024-08-15T13:49:24.956Z",
      "owner": {
        "id": "20463950-ed28-4a96-a13f-37af7100760e",
        "nickname": "estrela_mountainboss",
        "displayName": "estrela_mountain_dog",
        "images": [
          {
            "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
            "filename": "dev_avatar_1716195400.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
            "imageType": "avatar",
            "createdAt": "2024-05-20T09:16:36.924Z"
          },
          {
            "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
            "filename": "dev_background_1716824353.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
            "imageType": "background",
            "createdAt": "2024-05-27T15:39:15.656Z"
          }
        ],
        "isCreator": true
      },
      "likeUsers": [],
      "delayDate": null,
      "countComments": 0
    },
    {
      "id": "fb9a3390-f227-42ec-9d47-f22f0a95ba90",
      "text": "PAID: photo + video",
      "isPaid": false,
      "price": {
        "id": "9e2c3caf-83b8-43d5-aab5-6733d304e944",
        "amount": 8.4,
        "amountInCoins": 840,
        "productType": "post",
        "rawAmount": 8,
        "rawAmountInCoins": 800,
        "fee": 40,
        "archived": false,
        "currency": "USD",
        "discount": null,
        "discountPrice": null,
        "targetId": "fb9a3390-f227-42ec-9d47-f22f0a95ba90",
        "userId": "20463950-ed28-4a96-a13f-37af7100760e",
        "createdAt": "2024-08-15T13:48:45.942Z"
      },
      "commentsEnabled": true,
      "access": "free",
      "state": "published",
      "pinned": false,
      "likes": 0,
      "ownerId": "20463950-ed28-4a96-a13f-37af7100760e",
      "hasPrice": true,
      "liked": false,
      "attachments": [
        {
          "id": "09e0342c-773f-4d08-b807-9497551aa1f2",
          "hubId": "394821fb-bdda-42fc-9007-feb89a2c4450",
          "position": 0,
          "file": {
            "id": "394821fb-bdda-42fc-9007-feb89a2c4450",
            "owner": "CREATOR",
            "file": {
              "uploadId": "040defbb-7a8a-4951-b893-e15d44141337",
              "status": "UPLOADED",
              "name": "3e2f817d-c081-4193-90a9-938267ece286.mp4",
              "type": "VIDEO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/040defbb-7a8a-4951-b893-e15d44141337/blur_3e2f817d-c081-4193-90a9-938267ece286.webp",
              "avatar": null,
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/040defbb-7a8a-4951-b893-e15d44141337/blur_3e2f817d-c081-4193-90a9-938267ece286.webp",
              "thumbnail": null,
              "createdAt": "2024-05-23T15:54:05.661Z",
              "updatedAt": "2024-05-23T15:54:11.057Z",
              "deletedAt": null
            },
            "folders": [],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-23T15:54:11.051Z",
            "updatedAt": "2024-05-23T15:54:11.051Z",
            "deletedAt": null
          }
        }
      ],
      "createdAt": "2024-08-15T13:48:45.932Z",
      "updatedAt": "2024-08-15T13:48:45.932Z",
      "owner": {
        "id": "20463950-ed28-4a96-a13f-37af7100760e",
        "nickname": "estrela_mountainboss",
        "displayName": "estrela_mountain_dog",
        "images": [
          {
            "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
            "filename": "dev_avatar_1716195400.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
            "imageType": "avatar",
            "createdAt": "2024-05-20T09:16:36.924Z"
          },
          {
            "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
            "filename": "dev_background_1716824353.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
            "imageType": "background",
            "createdAt": "2024-05-27T15:39:15.656Z"
          }
        ],
        "isCreator": true
      },
      "likeUsers": [],
      "delayDate": null,
      "countComments": 0
    },
    {
      "id": "9081128b-97ff-4aa5-b801-c75aba95b063",
      "text": "FREE: photo + video",
      "isPaid": false,
      "price": null,
      "commentsEnabled": true,
      "access": "free",
      "state": "published",
      "pinned": false,
      "likes": 0,
      "ownerId": "20463950-ed28-4a96-a13f-37af7100760e",
      "hasPrice": false,
      "liked": false,
      "attachments": [
        {
          "id": "f9e7d897-a1de-415e-80f7-703e8719ae7c",
          "hubId": "ed0cce5a-df2c-4497-abc7-142346434f12",
          "position": 0,
          "file": {
            "id": "ed0cce5a-df2c-4497-abc7-142346434f12",
            "owner": "CREATOR",
            "file": {
              "uploadId": "10eff304-58de-4c77-95a2-fdc16576f747",
              "status": "UPLOADED",
              "name": "22c4d62c-fca5-40fc-a9b8-9aa2dda6f584.mp4",
              "type": "VIDEO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/10eff304-58de-4c77-95a2-fdc16576f747/22c4d62c-fca5-40fc-a9b8-9aa2dda6f584.mp4",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/10eff304-58de-4c77-95a2-fdc16576f747/avatar_22c4d62c-fca5-40fc-a9b8-9aa2dda6f584.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/10eff304-58de-4c77-95a2-fdc16576f747/blur_22c4d62c-fca5-40fc-a9b8-9aa2dda6f584.webp",
              "thumbnail": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/10eff304-58de-4c77-95a2-fdc16576f747/thumbnail_22c4d62c-fca5-40fc-a9b8-9aa2dda6f584.webp",
              "createdAt": "2024-05-23T16:01:56.640Z",
              "updatedAt": "2024-05-23T16:01:57.858Z",
              "deletedAt": null
            },
            "folders": [
              {
                "id": "1414bb56-855c-4919-b4ed-7c1a81917efe",
                "name": "Super",
                "last": [],
                "user": {
                  "id": "20463950-ed28-4a96-a13f-37af7100760e",
                  "nickname": "estrela_mountainboss",
                  "displayName": "estrela_mountain_dog",
                  "isCreator": true,
                  "kycVerified": true,
                  "creatorDetails": {
                    "displayName": "estrela_mountain_dog"
                  },
                  "images": [
                    {
                      "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                      "filename": "dev_avatar_1716195400.webp",
                      "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "avatar",
                      "createdAt": "2024-05-20T09:16:36.924Z"
                    },
                    {
                      "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                      "filename": "dev_background_1716824353.webp",
                      "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "background",
                      "createdAt": "2024-05-27T15:39:15.656Z"
                    }
                  ]
                },
                "createdAt": "2024-05-20T09:32:13.127Z",
                "updatedAt": "2024-05-20T09:32:13.127Z",
                "deletedAt": null
              }
            ],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-23T16:01:57.854Z",
            "updatedAt": "2024-05-23T16:01:57.854Z",
            "deletedAt": null
          }
        }
      ],
      "createdAt": "2024-08-15T13:47:34.812Z",
      "updatedAt": "2024-08-15T13:47:34.812Z",
      "owner": {
        "id": "20463950-ed28-4a96-a13f-37af7100760e",
        "nickname": "estrela_mountainboss",
        "displayName": "estrela_mountain_dog",
        "images": [
          {
            "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
            "filename": "dev_avatar_1716195400.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
            "imageType": "avatar",
            "createdAt": "2024-05-20T09:16:36.924Z"
          },
          {
            "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
            "filename": "dev_background_1716824353.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
            "imageType": "background",
            "createdAt": "2024-05-27T15:39:15.656Z"
          }
        ],
        "isCreator": true
      },
      "likeUsers": [],
      "delayDate": null,
      "countComments": 0
    },
    {
      "id": "4bc242e8-30fc-4cfd-a72e-57b21afe64f7",
      "text": "big free post ",
      "isPaid": false,
      "price": null,
      "commentsEnabled": true,
      "access": "free",
      "state": "published",
      "pinned": false,
      "likes": 1,
      "ownerId": "20463950-ed28-4a96-a13f-37af7100760e",
      "hasPrice": false,
      "liked": false,
      "attachments": [
        {
          "id": "d4c9a081-fefc-4c03-ab81-b5177802ba76",
          "hubId": "2023baad-3993-4001-afa2-55c175465fb0",
          "position": 7,
          "file": {
            "id": "2023baad-3993-4001-afa2-55c175465fb0",
            "owner": "CREATOR",
            "file": {
              "uploadId": "e6c2cdcf-9cb8-4bc0-bc7b-674fcdf665d0",
              "status": "UPLOADED",
              "name": "3e824f2f-500f-4da9-9c9b-c32c8f817f04.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/e6c2cdcf-9cb8-4bc0-bc7b-674fcdf665d0/3e824f2f-500f-4da9-9c9b-c32c8f817f04.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/e6c2cdcf-9cb8-4bc0-bc7b-674fcdf665d0/avatar_3e824f2f-500f-4da9-9c9b-c32c8f817f04.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/e6c2cdcf-9cb8-4bc0-bc7b-674fcdf665d0/blur_3e824f2f-500f-4da9-9c9b-c32c8f817f04.jpg",
              "createdAt": "2024-05-21T14:09:04.041Z",
              "updatedAt": "2024-07-26T12:40:42.614Z",
              "deletedAt": null
            },
            "folders": [],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-21T14:09:04.968Z",
            "updatedAt": "2024-05-21T14:09:04.968Z",
            "deletedAt": null
          }
        },
        {
          "id": "732a4302-2a73-40d3-af1c-756f67b25f67",
          "hubId": "a1a4d693-bcaa-4ca3-ba6d-eceb072c15d4",
          "position": 6,
          "file": {
            "id": "a1a4d693-bcaa-4ca3-ba6d-eceb072c15d4",
            "owner": "CREATOR",
            "file": {
              "uploadId": "5f74fc95-9d84-4777-9071-2440ba60db7b",
              "status": "UPLOADED",
              "name": "9ef30039-1e0c-4793-9517-b476577e3360.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/5f74fc95-9d84-4777-9071-2440ba60db7b/9ef30039-1e0c-4793-9517-b476577e3360.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/5f74fc95-9d84-4777-9071-2440ba60db7b/avatar_9ef30039-1e0c-4793-9517-b476577e3360.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/5f74fc95-9d84-4777-9071-2440ba60db7b/blur_9ef30039-1e0c-4793-9517-b476577e3360.jpg",
              "createdAt": "2024-05-22T13:30:38.978Z",
              "updatedAt": "2024-07-26T12:46:22.215Z",
              "deletedAt": null
            },
            "folders": [],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-22T13:30:40.105Z",
            "updatedAt": "2024-05-22T13:30:40.105Z",
            "deletedAt": null
          }
        },
        {
          "id": "f5943c07-bccb-4cc8-8ad0-3e3802520529",
          "hubId": "ec46e1d1-101a-4752-8b5b-1d2cb0a56d42",
          "position": 5,
          "file": {
            "id": "ec46e1d1-101a-4752-8b5b-1d2cb0a56d42",
            "owner": "CREATOR",
            "file": {
              "uploadId": "8ed2b6e9-1681-44c1-9fa7-6733fcb6ef9d",
              "status": "UPLOADED",
              "name": "c8c0d9fa-7f00-410f-b921-449e8ad663e1.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/8ed2b6e9-1681-44c1-9fa7-6733fcb6ef9d/c8c0d9fa-7f00-410f-b921-449e8ad663e1.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/8ed2b6e9-1681-44c1-9fa7-6733fcb6ef9d/avatar_c8c0d9fa-7f00-410f-b921-449e8ad663e1.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/8ed2b6e9-1681-44c1-9fa7-6733fcb6ef9d/blur_c8c0d9fa-7f00-410f-b921-449e8ad663e1.jpg",
              "createdAt": "2024-05-23T15:30:53.247Z",
              "updatedAt": "2024-07-26T12:47:46.712Z",
              "deletedAt": null
            },
            "folders": [],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-23T15:30:54.585Z",
            "updatedAt": "2024-05-23T15:30:54.585Z",
            "deletedAt": null
          }
        },
        {
          "id": "1fef0fca-02f8-4b05-8108-5dd3d06e9ee6",
          "hubId": "394821fb-bdda-42fc-9007-feb89a2c4450",
          "position": 4,
          "file": {
            "id": "394821fb-bdda-42fc-9007-feb89a2c4450",
            "owner": "CREATOR",
            "file": {
              "uploadId": "040defbb-7a8a-4951-b893-e15d44141337",
              "status": "UPLOADED",
              "name": "3e2f817d-c081-4193-90a9-938267ece286.mp4",
              "type": "VIDEO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/040defbb-7a8a-4951-b893-e15d44141337/3e2f817d-c081-4193-90a9-938267ece286.mp4",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/040defbb-7a8a-4951-b893-e15d44141337/avatar_3e2f817d-c081-4193-90a9-938267ece286.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/040defbb-7a8a-4951-b893-e15d44141337/blur_3e2f817d-c081-4193-90a9-938267ece286.webp",
              "thumbnail": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/040defbb-7a8a-4951-b893-e15d44141337/thumbnail_3e2f817d-c081-4193-90a9-938267ece286.webp",
              "createdAt": "2024-05-23T15:54:05.661Z",
              "updatedAt": "2024-05-23T15:54:11.057Z",
              "deletedAt": null
            },
            "folders": [],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-23T15:54:11.051Z",
            "updatedAt": "2024-05-23T15:54:11.051Z",
            "deletedAt": null
          }
        },
        {
          "id": "5a9ff41e-87b2-4806-94b7-ed01473dc609",
          "hubId": "ed0cce5a-df2c-4497-abc7-142346434f12",
          "position": 3,
          "file": {
            "id": "ed0cce5a-df2c-4497-abc7-142346434f12",
            "owner": "CREATOR",
            "file": {
              "uploadId": "10eff304-58de-4c77-95a2-fdc16576f747",
              "status": "UPLOADED",
              "name": "22c4d62c-fca5-40fc-a9b8-9aa2dda6f584.mp4",
              "type": "VIDEO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/10eff304-58de-4c77-95a2-fdc16576f747/22c4d62c-fca5-40fc-a9b8-9aa2dda6f584.mp4",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/10eff304-58de-4c77-95a2-fdc16576f747/avatar_22c4d62c-fca5-40fc-a9b8-9aa2dda6f584.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/10eff304-58de-4c77-95a2-fdc16576f747/blur_22c4d62c-fca5-40fc-a9b8-9aa2dda6f584.webp",
              "thumbnail": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/10eff304-58de-4c77-95a2-fdc16576f747/thumbnail_22c4d62c-fca5-40fc-a9b8-9aa2dda6f584.webp",
              "createdAt": "2024-05-23T16:01:56.640Z",
              "updatedAt": "2024-05-23T16:01:57.858Z",
              "deletedAt": null
            },
            "folders": [
              {
                "id": "1414bb56-855c-4919-b4ed-7c1a81917efe",
                "name": "Super",
                "last": [],
                "user": {
                  "id": "20463950-ed28-4a96-a13f-37af7100760e",
                  "nickname": "estrela_mountainboss",
                  "displayName": "estrela_mountain_dog",
                  "isCreator": true,
                  "kycVerified": true,
                  "creatorDetails": {
                    "displayName": "estrela_mountain_dog"
                  },
                  "images": [
                    {
                      "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                      "filename": "dev_avatar_1716195400.webp",
                      "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "avatar",
                      "createdAt": "2024-05-20T09:16:36.924Z"
                    },
                    {
                      "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                      "filename": "dev_background_1716824353.webp",
                      "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "background",
                      "createdAt": "2024-05-27T15:39:15.656Z"
                    }
                  ]
                },
                "createdAt": "2024-05-20T09:32:13.127Z",
                "updatedAt": "2024-05-20T09:32:13.127Z",
                "deletedAt": null
              }
            ],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-23T16:01:57.854Z",
            "updatedAt": "2024-05-23T16:01:57.854Z",
            "deletedAt": null
          }
        },
        {
          "id": "c3fd8a62-00b8-4f63-abe7-b5e5fbe2e411",
          "hubId": "cf1c7a31-06de-425d-ab98-df9850355896",
          "position": 2,
          "file": {
            "id": "cf1c7a31-06de-425d-ab98-df9850355896",
            "owner": "CREATOR",
            "file": {
              "uploadId": "5f961e70-d03f-40f6-854d-bb981b27045c",
              "status": "UPLOADED",
              "name": "a5174631-65ed-44b5-bd77-3f87a3b07a30.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/5f961e70-d03f-40f6-854d-bb981b27045c/a5174631-65ed-44b5-bd77-3f87a3b07a30.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/5f961e70-d03f-40f6-854d-bb981b27045c/avatar_a5174631-65ed-44b5-bd77-3f87a3b07a30.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/5f961e70-d03f-40f6-854d-bb981b27045c/blur_a5174631-65ed-44b5-bd77-3f87a3b07a30.jpg",
              "createdAt": "2024-05-23T16:02:13.086Z",
              "updatedAt": "2024-07-26T12:47:51.630Z",
              "deletedAt": null
            },
            "folders": [
              {
                "id": "1414bb56-855c-4919-b4ed-7c1a81917efe",
                "name": "Super",
                "last": [],
                "user": {
                  "id": "20463950-ed28-4a96-a13f-37af7100760e",
                  "nickname": "estrela_mountainboss",
                  "displayName": "estrela_mountain_dog",
                  "isCreator": true,
                  "kycVerified": true,
                  "creatorDetails": {
                    "displayName": "estrela_mountain_dog"
                  },
                  "images": [
                    {
                      "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                      "filename": "dev_avatar_1716195400.webp",
                      "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "avatar",
                      "createdAt": "2024-05-20T09:16:36.924Z"
                    },
                    {
                      "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                      "filename": "dev_background_1716824353.webp",
                      "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "background",
                      "createdAt": "2024-05-27T15:39:15.656Z"
                    }
                  ]
                },
                "createdAt": "2024-05-20T09:32:13.127Z",
                "updatedAt": "2024-05-20T09:32:13.127Z",
                "deletedAt": null
              }
            ],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-23T16:02:14.166Z",
            "updatedAt": "2024-05-23T16:02:14.166Z",
            "deletedAt": null
          }
        },
        {
          "id": "c27f5590-3a34-4a99-9c82-32ffefc8b2dd",
          "hubId": "c30f5d40-7372-42bf-adcc-7b55d7422daf",
          "position": 1,
          "file": {
            "id": "c30f5d40-7372-42bf-adcc-7b55d7422daf",
            "owner": "CREATOR",
            "file": {
              "uploadId": "635b8adb-d491-4d75-962f-ec4574925599",
              "status": "UPLOADED",
              "name": "a1b72021-0ddd-4cbb-b3c9-1821ea4c681a.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/635b8adb-d491-4d75-962f-ec4574925599/a1b72021-0ddd-4cbb-b3c9-1821ea4c681a.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/635b8adb-d491-4d75-962f-ec4574925599/avatar_a1b72021-0ddd-4cbb-b3c9-1821ea4c681a.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/635b8adb-d491-4d75-962f-ec4574925599/blur_a1b72021-0ddd-4cbb-b3c9-1821ea4c681a.jpg",
              "createdAt": "2024-05-23T16:02:13.086Z",
              "updatedAt": "2024-07-26T12:47:48.184Z",
              "deletedAt": null
            },
            "folders": [
              {
                "id": "1414bb56-855c-4919-b4ed-7c1a81917efe",
                "name": "Super",
                "last": [],
                "user": {
                  "id": "20463950-ed28-4a96-a13f-37af7100760e",
                  "nickname": "estrela_mountainboss",
                  "displayName": "estrela_mountain_dog",
                  "isCreator": true,
                  "kycVerified": true,
                  "creatorDetails": {
                    "displayName": "estrela_mountain_dog"
                  },
                  "images": [
                    {
                      "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                      "filename": "dev_avatar_1716195400.webp",
                      "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "avatar",
                      "createdAt": "2024-05-20T09:16:36.924Z"
                    },
                    {
                      "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                      "filename": "dev_background_1716824353.webp",
                      "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "background",
                      "createdAt": "2024-05-27T15:39:15.656Z"
                    }
                  ]
                },
                "createdAt": "2024-05-20T09:32:13.127Z",
                "updatedAt": "2024-05-20T09:32:13.127Z",
                "deletedAt": null
              }
            ],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-23T16:02:14.166Z",
            "updatedAt": "2024-05-23T16:02:14.166Z",
            "deletedAt": null
          }
        },
        {
          "id": "d8ecf11e-ed02-438c-865e-de07edc6a667",
          "hubId": "5452cb15-3e7a-4d55-8edb-efc2013a6872",
          "position": 0,
          "file": {
            "id": "5452cb15-3e7a-4d55-8edb-efc2013a6872",
            "owner": "CREATOR",
            "file": {
              "uploadId": "e2586757-b227-4131-b06e-8382cedc4a44",
              "status": "UPLOADED",
              "name": "17f81c6c-2fa6-4ae8-a4aa-602c39d9f945.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/e2586757-b227-4131-b06e-8382cedc4a44/17f81c6c-2fa6-4ae8-a4aa-602c39d9f945.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/e2586757-b227-4131-b06e-8382cedc4a44/avatar_17f81c6c-2fa6-4ae8-a4aa-602c39d9f945.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/e2586757-b227-4131-b06e-8382cedc4a44/blur_17f81c6c-2fa6-4ae8-a4aa-602c39d9f945.jpg",
              "createdAt": "2024-05-23T16:02:13.086Z",
              "updatedAt": "2024-07-26T12:47:49.813Z",
              "deletedAt": null
            },
            "folders": [
              {
                "id": "1414bb56-855c-4919-b4ed-7c1a81917efe",
                "name": "Super",
                "last": [],
                "user": {
                  "id": "20463950-ed28-4a96-a13f-37af7100760e",
                  "nickname": "estrela_mountainboss",
                  "displayName": "estrela_mountain_dog",
                  "isCreator": true,
                  "kycVerified": true,
                  "creatorDetails": {
                    "displayName": "estrela_mountain_dog"
                  },
                  "images": [
                    {
                      "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                      "filename": "dev_avatar_1716195400.webp",
                      "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "avatar",
                      "createdAt": "2024-05-20T09:16:36.924Z"
                    },
                    {
                      "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                      "filename": "dev_background_1716824353.webp",
                      "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "background",
                      "createdAt": "2024-05-27T15:39:15.656Z"
                    }
                  ]
                },
                "createdAt": "2024-05-20T09:32:13.127Z",
                "updatedAt": "2024-05-20T09:32:13.127Z",
                "deletedAt": null
              }
            ],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-23T16:02:14.166Z",
            "updatedAt": "2024-05-23T16:02:14.166Z",
            "deletedAt": null
          }
        },
        {
          "id": "7d81b05b-3926-459b-91e7-e9d47fc79ee1",
          "hubId": "1f6281c7-71a6-4602-b019-adff168cdece",
          "position": 11,
          "file": {
            "id": "1f6281c7-71a6-4602-b019-adff168cdece",
            "owner": "CREATOR",
            "file": {
              "uploadId": "5cbd3885-f9a1-4d65-90cd-b0e1e0730306",
              "status": "UPLOADED",
              "name": "3107144c-2ded-4b0c-a016-215f36026256.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/5cbd3885-f9a1-4d65-90cd-b0e1e0730306/3107144c-2ded-4b0c-a016-215f36026256.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/5cbd3885-f9a1-4d65-90cd-b0e1e0730306/avatar_3107144c-2ded-4b0c-a016-215f36026256.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/5cbd3885-f9a1-4d65-90cd-b0e1e0730306/blur_3107144c-2ded-4b0c-a016-215f36026256.jpg",
              "createdAt": "2024-05-20T09:22:16.868Z",
              "updatedAt": "2024-07-26T12:36:39.328Z",
              "deletedAt": null
            },
            "folders": [],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-20T09:22:17.965Z",
            "updatedAt": "2024-05-20T09:22:17.965Z",
            "deletedAt": null
          }
        },
        {
          "id": "ee0c2c73-d6b1-4f6a-908c-57fa66fc70f8",
          "hubId": "9abce0bb-9278-4b01-99a7-1c7e2eb398a6",
          "position": 10,
          "file": {
            "id": "9abce0bb-9278-4b01-99a7-1c7e2eb398a6",
            "owner": "CREATOR",
            "file": {
              "uploadId": "536c0602-0e8f-4b6b-8bd8-e69212ae3307",
              "status": "UPLOADED",
              "name": "851e17b5-1625-418c-bc86-48482128ebda.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/536c0602-0e8f-4b6b-8bd8-e69212ae3307/851e17b5-1625-418c-bc86-48482128ebda.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/536c0602-0e8f-4b6b-8bd8-e69212ae3307/avatar_851e17b5-1625-418c-bc86-48482128ebda.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/536c0602-0e8f-4b6b-8bd8-e69212ae3307/blur_851e17b5-1625-418c-bc86-48482128ebda.jpg",
              "createdAt": "2024-05-20T09:32:22.193Z",
              "updatedAt": "2024-07-26T12:36:40.197Z",
              "deletedAt": null
            },
            "folders": [
              {
                "id": "1414bb56-855c-4919-b4ed-7c1a81917efe",
                "name": "Super",
                "last": [],
                "user": {
                  "id": "20463950-ed28-4a96-a13f-37af7100760e",
                  "nickname": "estrela_mountainboss",
                  "displayName": "estrela_mountain_dog",
                  "isCreator": true,
                  "kycVerified": true,
                  "creatorDetails": {
                    "displayName": "estrela_mountain_dog"
                  },
                  "images": [
                    {
                      "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                      "filename": "dev_avatar_1716195400.webp",
                      "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "avatar",
                      "createdAt": "2024-05-20T09:16:36.924Z"
                    },
                    {
                      "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                      "filename": "dev_background_1716824353.webp",
                      "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                      "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                      "imageType": "background",
                      "createdAt": "2024-05-27T15:39:15.656Z"
                    }
                  ]
                },
                "createdAt": "2024-05-20T09:32:13.127Z",
                "updatedAt": "2024-05-20T09:32:13.127Z",
                "deletedAt": null
              }
            ],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-20T09:32:22.794Z",
            "updatedAt": "2024-05-20T09:32:22.794Z",
            "deletedAt": null
          }
        },
        {
          "id": "1a00319e-7d40-46ca-b3e2-976bae548e70",
          "hubId": "f4d7aa28-a6cb-47bb-9a4c-307a996c6ea7",
          "position": 9,
          "file": {
            "id": "f4d7aa28-a6cb-47bb-9a4c-307a996c6ea7",
            "owner": "CREATOR",
            "file": {
              "uploadId": "dd788af6-a969-4e26-942f-06fe6ff01aaf",
              "status": "UPLOADED",
              "name": "576cd7cc-c6d4-498b-accb-cee0a0b5b587.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/dd788af6-a969-4e26-942f-06fe6ff01aaf/576cd7cc-c6d4-498b-accb-cee0a0b5b587.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/dd788af6-a969-4e26-942f-06fe6ff01aaf/avatar_576cd7cc-c6d4-498b-accb-cee0a0b5b587.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/dd788af6-a969-4e26-942f-06fe6ff01aaf/blur_576cd7cc-c6d4-498b-accb-cee0a0b5b587.jpg",
              "createdAt": "2024-05-21T07:50:55.557Z",
              "updatedAt": "2024-07-26T12:38:05.741Z",
              "deletedAt": null
            },
            "folders": [],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-21T07:51:02.957Z",
            "updatedAt": "2024-05-21T07:51:02.957Z",
            "deletedAt": null
          }
        },
        {
          "id": "25290f4a-cb3d-448d-a24f-381b791c3b2f",
          "hubId": "08905d96-adb8-436f-9b08-b952394684c7",
          "position": 8,
          "file": {
            "id": "08905d96-adb8-436f-9b08-b952394684c7",
            "owner": "CREATOR",
            "file": {
              "uploadId": "6aca919e-3914-4ec7-8065-aad349fc953e",
              "status": "UPLOADED",
              "name": "faf8335d-c15b-42cc-9cd1-988063f4ce59.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/6aca919e-3914-4ec7-8065-aad349fc953e/faf8335d-c15b-42cc-9cd1-988063f4ce59.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/6aca919e-3914-4ec7-8065-aad349fc953e/avatar_faf8335d-c15b-42cc-9cd1-988063f4ce59.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/6aca919e-3914-4ec7-8065-aad349fc953e/blur_faf8335d-c15b-42cc-9cd1-988063f4ce59.jpg",
              "createdAt": "2024-05-21T14:06:09.087Z",
              "updatedAt": "2024-07-26T12:40:12.414Z",
              "deletedAt": null
            },
            "folders": [],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-21T14:06:09.998Z",
            "updatedAt": "2024-05-21T14:06:09.998Z",
            "deletedAt": null
          }
        }
      ],
      "createdAt": "2024-05-24T09:22:35.482Z",
      "updatedAt": "2024-07-05T10:16:55.349Z",
      "owner": {
        "id": "20463950-ed28-4a96-a13f-37af7100760e",
        "nickname": "estrela_mountainboss",
        "displayName": "estrela_mountain_dog",
        "images": [
          {
            "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
            "filename": "dev_avatar_1716195400.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
            "imageType": "avatar",
            "createdAt": "2024-05-20T09:16:36.924Z"
          },
          {
            "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
            "filename": "dev_background_1716824353.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
            "imageType": "background",
            "createdAt": "2024-05-27T15:39:15.656Z"
          }
        ],
        "isCreator": true
      },
      "likeUsers": [
        {
          "id": "591ad1c5-843b-4d24-bb7f-1827149ef520",
          "nickname": "rottava_poltava",
          "displayName": "2222",
          "images": [
            {
              "id": "fd9b966f-4ce8-4296-9c99-0191c53eb8b3",
              "filename": "dev_avatar_1715691340.webp",
              "userId": "591ad1c5-843b-4d24-bb7f-1827149ef520",
              "url": "https://store.dev.desiren.me/avatar/591ad1c5-843b-4d24-bb7f-1827149ef520/dev_avatar_1715691340.webp",
              "imageType": "avatar",
              "createdAt": "2024-05-14T12:58:57.800Z"
            },
            {
              "id": "23d6150b-dd3e-4a6f-b14c-2da024fecd44",
              "filename": "dev_background_1718707772.webp",
              "userId": "591ad1c5-843b-4d24-bb7f-1827149ef520",
              "url": "https://store.dev.desiren.me/background/591ad1c5-843b-4d24-bb7f-1827149ef520/dev_background_1718707772.webp",
              "imageType": "background",
              "createdAt": "2024-06-18T10:49:32.945Z"
            }
          ],
          "isCreator": true
        }
      ],
      "delayDate": null,
      "countComments": 1
    },
    {
      "id": "2ec07639-d162-4dde-9ded-a68c96699af1",
      "text": "gPaid",
      "isPaid": false,
      "price": {
        "id": "fd4d4000-6999-47b5-a6d5-0df60a5180dd",
        "amount": 10.5,
        "amountInCoins": 1050,
        "productType": "post",
        "rawAmount": 10,
        "rawAmountInCoins": 1000,
        "fee": 50,
        "archived": false,
        "currency": "USD",
        "discount": null,
        "discountPrice": null,
        "targetId": "2ec07639-d162-4dde-9ded-a68c96699af1",
        "userId": "20463950-ed28-4a96-a13f-37af7100760e",
        "createdAt": "2024-05-23T15:54:30.779Z"
      },
      "commentsEnabled": true,
      "access": "free",
      "state": "published",
      "pinned": false,
      "likes": 0,
      "ownerId": "20463950-ed28-4a96-a13f-37af7100760e",
      "hasPrice": true,
      "liked": false,
      "attachments": [
        {
          "id": "11ecd1b7-5efa-412b-b58d-82e79d2fc2d2",
          "hubId": "394821fb-bdda-42fc-9007-feb89a2c4450",
          "position": 0,
          "file": {
            "id": "394821fb-bdda-42fc-9007-feb89a2c4450",
            "owner": "CREATOR",
            "file": {
              "uploadId": "040defbb-7a8a-4951-b893-e15d44141337",
              "status": "UPLOADED",
              "name": "3e2f817d-c081-4193-90a9-938267ece286.mp4",
              "type": "VIDEO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/040defbb-7a8a-4951-b893-e15d44141337/blur_3e2f817d-c081-4193-90a9-938267ece286.webp",
              "avatar": null,
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/040defbb-7a8a-4951-b893-e15d44141337/blur_3e2f817d-c081-4193-90a9-938267ece286.webp",
              "thumbnail": null,
              "createdAt": "2024-05-23T15:54:05.661Z",
              "updatedAt": "2024-05-23T15:54:11.057Z",
              "deletedAt": null
            },
            "folders": [],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-23T15:54:11.051Z",
            "updatedAt": "2024-05-23T15:54:11.051Z",
            "deletedAt": null
          }
        }
      ],
      "createdAt": "2024-05-23T15:54:30.530Z",
      "updatedAt": "2024-07-05T10:16:55.349Z",
      "owner": {
        "id": "20463950-ed28-4a96-a13f-37af7100760e",
        "nickname": "estrela_mountainboss",
        "displayName": "estrela_mountain_dog",
        "images": [
          {
            "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
            "filename": "dev_avatar_1716195400.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
            "imageType": "avatar",
            "createdAt": "2024-05-20T09:16:36.924Z"
          },
          {
            "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
            "filename": "dev_background_1716824353.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
            "imageType": "background",
            "createdAt": "2024-05-27T15:39:15.656Z"
          }
        ],
        "isCreator": true
      },
      "likeUsers": [],
      "delayDate": null,
      "countComments": 1
    },
    {
      "id": "ff3ff7d8-9d44-4b70-b6e8-c78fb424a0ab",
      "text": "frrreee",
      "isPaid": false,
      "price": null,
      "commentsEnabled": true,
      "access": "free",
      "state": "published",
      "pinned": false,
      "likes": 0,
      "ownerId": "20463950-ed28-4a96-a13f-37af7100760e",
      "hasPrice": false,
      "liked": false,
      "attachments": [
        {
          "id": "0571ca87-474b-4286-bf2a-7c8c032ea61a",
          "hubId": "ec46e1d1-101a-4752-8b5b-1d2cb0a56d42",
          "position": 0,
          "file": {
            "id": "ec46e1d1-101a-4752-8b5b-1d2cb0a56d42",
            "owner": "CREATOR",
            "file": {
              "uploadId": "8ed2b6e9-1681-44c1-9fa7-6733fcb6ef9d",
              "status": "UPLOADED",
              "name": "c8c0d9fa-7f00-410f-b921-449e8ad663e1.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/8ed2b6e9-1681-44c1-9fa7-6733fcb6ef9d/c8c0d9fa-7f00-410f-b921-449e8ad663e1.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/8ed2b6e9-1681-44c1-9fa7-6733fcb6ef9d/avatar_c8c0d9fa-7f00-410f-b921-449e8ad663e1.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/8ed2b6e9-1681-44c1-9fa7-6733fcb6ef9d/blur_c8c0d9fa-7f00-410f-b921-449e8ad663e1.jpg",
              "createdAt": "2024-05-23T15:30:53.247Z",
              "updatedAt": "2024-07-26T12:47:46.712Z",
              "deletedAt": null
            },
            "folders": [],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-23T15:30:54.585Z",
            "updatedAt": "2024-05-23T15:30:54.585Z",
            "deletedAt": null
          }
        }
      ],
      "createdAt": "2024-05-23T15:31:04.624Z",
      "updatedAt": "2024-07-05T10:16:55.349Z",
      "owner": {
        "id": "20463950-ed28-4a96-a13f-37af7100760e",
        "nickname": "estrela_mountainboss",
        "displayName": "estrela_mountain_dog",
        "images": [
          {
            "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
            "filename": "dev_avatar_1716195400.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
            "imageType": "avatar",
            "createdAt": "2024-05-20T09:16:36.924Z"
          },
          {
            "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
            "filename": "dev_background_1716824353.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
            "imageType": "background",
            "createdAt": "2024-05-27T15:39:15.656Z"
          }
        ],
        "isCreator": true
      },
      "likeUsers": [],
      "delayDate": null,
      "countComments": 4
    },
    {
      "id": "e049c171-5720-4a11-91d7-288ec21a826d",
      "text": "paid",
      "isPaid": false,
      "price": {
        "id": "2220401a-f1ae-4a00-8f2f-df405a740189",
        "amount": 9.45,
        "amountInCoins": 945,
        "productType": "post",
        "rawAmount": 9,
        "rawAmountInCoins": 900,
        "fee": 45,
        "archived": false,
        "currency": "USD",
        "discount": null,
        "discountPrice": null,
        "targetId": "e049c171-5720-4a11-91d7-288ec21a826d",
        "userId": "20463950-ed28-4a96-a13f-37af7100760e",
        "createdAt": "2024-05-21T14:08:27.563Z"
      },
      "commentsEnabled": true,
      "access": "free",
      "state": "published",
      "pinned": false,
      "likes": 0,
      "ownerId": "20463950-ed28-4a96-a13f-37af7100760e",
      "hasPrice": true,
      "liked": false,
      "attachments": [
        {
          "id": "f9174df9-58e0-4972-a2d8-92fa3147748e",
          "hubId": "f4d7aa28-a6cb-47bb-9a4c-307a996c6ea7",
          "position": 0,
          "file": {
            "id": "f4d7aa28-a6cb-47bb-9a4c-307a996c6ea7",
            "owner": "CREATOR",
            "file": {
              "uploadId": "dd788af6-a969-4e26-942f-06fe6ff01aaf",
              "status": "UPLOADED",
              "name": "576cd7cc-c6d4-498b-accb-cee0a0b5b587.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/dd788af6-a969-4e26-942f-06fe6ff01aaf/blur_576cd7cc-c6d4-498b-accb-cee0a0b5b587.jpg",
              "avatar": null,
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/dd788af6-a969-4e26-942f-06fe6ff01aaf/blur_576cd7cc-c6d4-498b-accb-cee0a0b5b587.jpg",
              "createdAt": "2024-05-21T07:50:55.557Z",
              "updatedAt": "2024-07-26T12:38:05.741Z",
              "deletedAt": null
            },
            "folders": [],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-21T07:51:02.957Z",
            "updatedAt": "2024-05-21T07:51:02.957Z",
            "deletedAt": null
          }
        }
      ],
      "createdAt": "2024-05-21T14:08:27.557Z",
      "updatedAt": "2024-07-05T10:16:55.349Z",
      "owner": {
        "id": "20463950-ed28-4a96-a13f-37af7100760e",
        "nickname": "estrela_mountainboss",
        "displayName": "estrela_mountain_dog",
        "images": [
          {
            "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
            "filename": "dev_avatar_1716195400.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
            "imageType": "avatar",
            "createdAt": "2024-05-20T09:16:36.924Z"
          },
          {
            "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
            "filename": "dev_background_1716824353.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
            "imageType": "background",
            "createdAt": "2024-05-27T15:39:15.656Z"
          }
        ],
        "isCreator": true
      },
      "likeUsers": [],
      "delayDate": null,
      "countComments": 0
    },
    {
      "id": "94bbe6ac-2771-4e38-9249-754d86c55b68",
      "text": "free",
      "isPaid": false,
      "price": null,
      "commentsEnabled": true,
      "access": "free",
      "state": "published",
      "pinned": false,
      "likes": 0,
      "ownerId": "20463950-ed28-4a96-a13f-37af7100760e",
      "hasPrice": false,
      "liked": false,
      "attachments": [
        {
          "id": "ccb22aae-2555-43c7-867f-3a0c5b09c54f",
          "hubId": "08905d96-adb8-436f-9b08-b952394684c7",
          "position": 0,
          "file": {
            "id": "08905d96-adb8-436f-9b08-b952394684c7",
            "owner": "CREATOR",
            "file": {
              "uploadId": "6aca919e-3914-4ec7-8065-aad349fc953e",
              "status": "UPLOADED",
              "name": "faf8335d-c15b-42cc-9cd1-988063f4ce59.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/6aca919e-3914-4ec7-8065-aad349fc953e/faf8335d-c15b-42cc-9cd1-988063f4ce59.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/6aca919e-3914-4ec7-8065-aad349fc953e/avatar_faf8335d-c15b-42cc-9cd1-988063f4ce59.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/6aca919e-3914-4ec7-8065-aad349fc953e/blur_faf8335d-c15b-42cc-9cd1-988063f4ce59.jpg",
              "createdAt": "2024-05-21T14:06:09.087Z",
              "updatedAt": "2024-07-26T12:40:12.414Z",
              "deletedAt": null
            },
            "folders": [],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-21T14:06:09.998Z",
            "updatedAt": "2024-05-21T14:06:09.998Z",
            "deletedAt": null
          }
        }
      ],
      "createdAt": "2024-05-21T14:07:16.507Z",
      "updatedAt": "2024-07-05T10:16:55.349Z",
      "owner": {
        "id": "20463950-ed28-4a96-a13f-37af7100760e",
        "nickname": "estrela_mountainboss",
        "displayName": "estrela_mountain_dog",
        "images": [
          {
            "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
            "filename": "dev_avatar_1716195400.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
            "imageType": "avatar",
            "createdAt": "2024-05-20T09:16:36.924Z"
          },
          {
            "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
            "filename": "dev_background_1716824353.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
            "imageType": "background",
            "createdAt": "2024-05-27T15:39:15.656Z"
          }
        ],
        "isCreator": true
      },
      "likeUsers": [],
      "delayDate": null,
      "countComments": 0
    },
    {
      "id": "260e27eb-7f4b-4541-b23a-e562a90cee29",
      "text": "фриии",
      "isPaid": false,
      "price": null,
      "commentsEnabled": true,
      "access": "free",
      "state": "published",
      "pinned": false,
      "likes": 0,
      "ownerId": "20463950-ed28-4a96-a13f-37af7100760e",
      "hasPrice": false,
      "liked": false,
      "attachments": [
        {
          "id": "2b099dec-3689-49c3-96ba-1040d6bd4571",
          "hubId": "f4d7aa28-a6cb-47bb-9a4c-307a996c6ea7",
          "position": 0,
          "file": {
            "id": "f4d7aa28-a6cb-47bb-9a4c-307a996c6ea7",
            "owner": "CREATOR",
            "file": {
              "uploadId": "dd788af6-a969-4e26-942f-06fe6ff01aaf",
              "status": "UPLOADED",
              "name": "576cd7cc-c6d4-498b-accb-cee0a0b5b587.jpg",
              "type": "PHOTO",
              "url": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/dd788af6-a969-4e26-942f-06fe6ff01aaf/576cd7cc-c6d4-498b-accb-cee0a0b5b587.jpg",
              "avatar": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/dd788af6-a969-4e26-942f-06fe6ff01aaf/avatar_576cd7cc-c6d4-498b-accb-cee0a0b5b587.webp",
              "blur": "https://store.dev.desiren.me/hub/20463950-ed28-4a96-a13f-37af7100760e/dd788af6-a969-4e26-942f-06fe6ff01aaf/blur_576cd7cc-c6d4-498b-accb-cee0a0b5b587.jpg",
              "createdAt": "2024-05-21T07:50:55.557Z",
              "updatedAt": "2024-07-26T12:38:05.741Z",
              "deletedAt": null
            },
            "folders": [],
            "user": {
              "id": "20463950-ed28-4a96-a13f-37af7100760e",
              "nickname": "estrela_mountainboss",
              "displayName": "estrela_mountain_dog",
              "isCreator": true,
              "kycVerified": true,
              "creatorDetails": {
                "displayName": "estrela_mountain_dog"
              },
              "images": [
                {
                  "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
                  "filename": "dev_avatar_1716195400.webp",
                  "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "avatar",
                  "createdAt": "2024-05-20T09:16:36.924Z"
                },
                {
                  "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
                  "filename": "dev_background_1716824353.webp",
                  "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
                  "userId": "20463950-ed28-4a96-a13f-37af7100760e",
                  "imageType": "background",
                  "createdAt": "2024-05-27T15:39:15.656Z"
                }
              ]
            },
            "createdAt": "2024-05-21T07:51:02.957Z",
            "updatedAt": "2024-05-21T07:51:02.957Z",
            "deletedAt": null
          }
        }
      ],
      "createdAt": "2024-05-21T11:54:35.727Z",
      "updatedAt": "2024-07-05T10:16:55.349Z",
      "owner": {
        "id": "20463950-ed28-4a96-a13f-37af7100760e",
        "nickname": "estrela_mountainboss",
        "displayName": "estrela_mountain_dog",
        "images": [
          {
            "id": "fac39f4f-e22c-4c98-81c9-01c0cd3d83d8",
            "filename": "dev_avatar_1716195400.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/avatar/20463950-ed28-4a96-a13f-37af7100760e/dev_avatar_1716195400.webp",
            "imageType": "avatar",
            "createdAt": "2024-05-20T09:16:36.924Z"
          },
          {
            "id": "8a869c18-1801-4e7c-bbcb-27e0c15e9363",
            "filename": "dev_background_1716824353.webp",
            "userId": "20463950-ed28-4a96-a13f-37af7100760e",
            "url": "https://store.dev.desiren.me/background/20463950-ed28-4a96-a13f-37af7100760e/dev_background_1716824353.webp",
            "imageType": "background",
            "createdAt": "2024-05-27T15:39:15.656Z"
          }
        ],
        "isCreator": true
      },
      "likeUsers": [],
      "delayDate": null,
      "countComments": 0
    }
  ]
}
