"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMessagesMessageTypes = void 0;
var EMessagesMessageTypes;
(function (EMessagesMessageTypes) {
    EMessagesMessageTypes["TEXT"] = "TEXT";
    EMessagesMessageTypes["AUDIO"] = "AUDIO";
    EMessagesMessageTypes["VIDEO"] = "VIDEO";
    EMessagesMessageTypes["PHOTO"] = "PHOTO";
    EMessagesMessageTypes["DOCUMENT"] = "DOCUMENT";
    EMessagesMessageTypes["VOICE"] = "VOICE";
    EMessagesMessageTypes["MULTIPART"] = "MULTIPART";
})(EMessagesMessageTypes || (exports.EMessagesMessageTypes = EMessagesMessageTypes = {}));
